<template lang="pug">
.ConfirmSchedule
  Header
  div(style="margin-top: 50px")
  h2(v-if="!modeScheduled").TitleSection.text-center.pt-3.pb-3#headerCalendarTitle Confirma tu videollamada
  .headerFixed(v-if="isntIntersection && !modeScheduled")
    button(@click="() => goToView('Calendar')" type="button").button-without-styles.py-3.px-4
      svg(width='20' height='19' viewbox='0 0 20 19' fill='none' xmlns='http://www.w3.org/2000/svg')
        path(d='M9.55301 16.8398L2.26586 9.55269L9.55301 2.26554M3.27796 9.55269L18.0547 9.55269' stroke='#ffff' stroke-width='3.12307' stroke-linecap='round' stroke-linejoin='round')
    h2.TitleSection.text-title.color-white.pt-2 Confirma tu videollamada
  .botton-actions.ml-5(v-if="!modeScheduled")
    .d-flex.justify-content-between
      button(type="button" @click="() => goToView('Calendar')").button-without-styles
        svg(width='20' height='19' viewbox='0 0 20 19' fill='none' xmlns='http://www.w3.org/2000/svg')
          path(d='M9.55301 16.8398L2.26586 9.55269L9.55301 2.26554M3.27796 9.55269L18.0547 9.55269' stroke='#0058A2' stroke-width='3.12307' stroke-linecap='round' stroke-linejoin='round')
  h2(v-else-if="modeScheduled &&  responseAppointment.status != 'CONFIRMED'").TitleSection.pt-5.mb-4.pb-1.warning Error agendando la cita 
    br
    br
    p.font-16.mb-0.text-error {{responseAppointment.message}}
  .text-center.d-flex.flex-wrap(v-else style="justify-content: center; align-items: baseline;").mt-4
    h2.TitleSection.pt-5.ProgramTitle.m-0
      span.red(style="font-size:30px") ¡
      span.blue(style="font-size:30px") Tu videollamada se
    h2.TitleSection.ProgramTitle.m-0
      span.blue(style="font-size:30px") agendó con éxito
    h2.TitleSection.mb-4.pb-1.ProgramTitle
      span.red !
  .form-data.mb-3.mt-0.py-4.mx-1.px-4(v-if="!modeScheduled")
    p.FontDesc.mb-2 Confirma los datos registrados para finalizar tu agendamiento:
    br
    p.mb-0.ml-1 Nombre:
      strong.ml-1 {{ client.Nombres }}
      strong.ml-1 {{ client.PrimerApellido }} {{ client.SegundoApellido }}
    p.font-16.mb-0 Fecha y hora:
      strong.ml-1 {{ confirmDay }}
    p.font-16.mb-0(v-if="servicioSelected || service") Servicio:
      //- strong.ml-1 {{ servicioSelected ? servicioSelected.label : service }}
      strong.ml-1 Autorizaciones médicas
    p.font-16.mb-0 Modalidad de atención:
      strong.ml-1 Videollamada
    //- p.font-16.mb-0 Modalidad de atención:
    //-   strong.ml-1 {{ modeAtt }}
    //- p(v-if="responseAppointment.code").font-16.mt-4.text-center Número de confirmación de cita:
    //-   strong.ml-1.d-block {{responseAppointment.code}}
    a.mx-2.LinkColored.mt-4.pt-2.pb-1.text-center.d-block Te enviaremos la confirmación a tu correo electrónico
      p.mb-0.ml-1.d-flex.flex-wrap.justify-content-center
        span {{ email1 }}
        span {{ email2 }}
      span.ml-1 y a tu número celular
      span.ml-1 {{ phone }}
  .text-center.pb-5(v-else)
    p.grayTextDesc.pt-4 Enviaremos a tu correo electrónico y número de celular:
    p.grayTextDesc.pb-5(style="color: black;")
      b
        span El Link que te permitirá ingresar y activar tu videollamada
        strong.mx-1(style="color: #0BB257;") 5 minutos
        span antes de la hora programada.
        br
        span.red ¡ 
        span.blue Recuerda Revisarlo
        span.red !
  .botton-actions-footer
    .d-flex.justify-content-center
      b-button(
        variant="bluecolmedica"
        class="text-center"
        size="sm"
        type="button"
        @click="confirmAppointment"
        :loading="loading"
        v-if="!modeScheduled").w-100.py-2.mx-1
        span(v-if="!loading").font-md Confirmar
        .py-1(v-else)
          MiniSpinner
      //- b-button(
      //-   variant="whitecolmedica"
      //-   class="text-center"
      //-   disabled
      //-   size="sm"
      //-   @click="() => goToView('Home')"
      //-   type="button"
      //-   v-else).w-100.py-2.mx-1.buttonOpacity
      //-   span.font-md Ya puedes cerrar esta ventana
    b-button(
      variant="bluecolmedica"
      @click="onFinish"
      v-if="modeScheduled"
      style="background-color: #ff0304; border: none;"
    ).mt-2.d-block.text-center.py-2.mb-4.w-100
      span.font-md Finalizar
    button(
      @click="() => goToView('ToSchedule')"
      v-if="modeScheduled"
    ).mt-2.d-block.text-center.py-1.mb-4.link-btn-fake Ver videollamadas programadas
</template>

<script>
import asterisker from "@/helpers/asteriskHidden";
import _get from "lodash/get";
import { mapActions, mapState } from "vuex";
import moment from "moment";
import MiniSpinner from "../components/MiniSpinner";
import clientInfo from "@/helpers/clientInfo";
import { getCompany } from "@/helpers/Meet";
import LS from "@/helpers/localStorage";
import Header from "./components/Header";

export default {
  name: "ConfirmSchedule",

  data: () => ({
    name: "Susan Fajardo",
    date: "13/02/2022, 9:00a.m.",
    serviceData: "Cardiologia",
    modeAtt: "Videollamada",
    modeScheduled: false,
    isntIntersection: false,
    loading: false
  }),

  components: {
    MiniSpinner,
    Header
  },

  mounted() {
    this.observer = new IntersectionObserver(this.handlerObserver, {
      threshold: 1.0
    });

    this.observer.observe(document.querySelector("#headerCalendarTitle"));
  },

  computed: {
    ...mapState({
      client: state => state.autoservice.client,
      b64final: state => state.autoservice.b64final,
      service: state => state.autoservice.service,
      selectedDay: state => state.appointments.selectedDay,
      env: state => state.env,
      servicioSelected: state => state.autoservice.servicioSelected,
      responseAppointment: state => state.appointments.responseAppointment,
      appointment: state => state.appointments.appointment
    }),

    phone() {
      let pure = _get(this.client, "Celular") || "";
      return asterisker(String(pure), 0, 4);
    },

    email1() {
      let pure = _get(this.client, "Correo") || "";
      let splited = pure.split("@");
      return `${asterisker(splited[0], 2, 2)}`;
    },

    email2() {
      let pure = _get(this.client, "Correo") || "";
      let splited = pure.split("@");
      return `${
        splited[1] ? "@" + asterisker(splited[1], 0, splited[1].length - 2) : ""
      }`;
    },

    confirmDay() {
      return moment(this.selectedDay.zonedStartDate)
        .locale("es")
        .format("DD/MMM/YYYY hh:mm a")
        ?.replace(".", "");
    }
  },

  methods: {
    ...mapActions({
      goToView: "autoservice/goToView",
      clearDocs: "autoservice/clearDocs",
      createAppointment: "appointments/createAppointment",
      updateAppointment: "appointments/updateAppointment",
      sendNotification: "appointments/sendNotification",
      setAppointment: "appointments/setAppointment"
    }),

    async onFinish() {
      // this.setAppointment(this.appointment);
      // this.goToView("CancelSchedule");
      localStorage.clear();
      this.goToView("Autenticate");
      this.clearDocs();
    },

    handlerObserver(entries) {
      entries.forEach(entry => {
        if (!entry.isIntersecting) {
          this.isntIntersection = true;
        } else {
          this.isntIntersection = false;
        }
      });
    },

    async confirmAppointment() {
      this.loading = true;

      if (this.appointment.code) {
        let data = {
          branch: this.appointment.branch?.id,
          status: "CONFIRMED",
          schedule: this.appointment.schedule?.id,
          code: this.appointment.code,
          startAt: this.selectedDay.zonedStartDate.replace("-05:00", "-0500")
        };

        await this.updateAppointment({
          url: this.env.VUE_APPOINTMENTS_DEBMEDIA_SERVER,
          headers: {
            token_appointments: this.env.VUE_APPOINTMENTS_DEBMEDIA_API_KEY
          },
          queryData: "",
          data: data
        });
      } else {
        console.log("this.b64final.ma");
        console.log(this.b64final.ma);

        let service = {};
        // search tramite
        if (this.b64final.tr) {
          const company = await getCompany(this.env.VUE_APP_COMPANY);
          console.log(company);
          const branch = _get(
            Object.keys(company).filter(
              name => company[name].branch_id == this.b64final.su
            ),
            "[0]"
          );
          const turns = company[branch]?.turnos;

          if (turns)
            service = turns.find(t => t.tramite_id == this.b64final.tr)
              ?.tramite_name;
        }

        // Create appointment
        let data = {
          branch: this.env.VUE_APPOINTMENTS_DEFAULT_BRANCH,
          schedule: this.env.VUE_APPOINTMENTS_DEFAULT_SCHEDULE,
          startAt: this.selectedDay.zonedStartDate.replace("-05:00", "-0500"), //remove : character
          user: {
            firstName: this.client?.Nombres?.replace(/[^a-zA-Z ]/g, ""),
            lastName:
              this.client?.PrimerApellido?.replace(/[^a-zA-Z ]/g, "") +
              " " +
              (this.client?.SegundoApellido || "").replace(/[^a-zA-Z ]/g, ""),
            dni: this.client.NumeroIdentificacion,
            cuit: 1,
            email: this.client.Correo,
            phone: this.client.Celular
          },
          reason: JSON.stringify({
            servicioSelected:
              this.servicioSelected?.label || service || "No registra",
            modalidad: this.modeAtt,
            tramite:
            this.b64final.tr || this.client.tr || this.env.VUE_APP_DEBMEDIA_DEFAULT_TRAMITE,
            marcacion: this.b64final.ma,
            so: this.client.so,
            fu: this.b64final.fu || this.client.fu || clientInfo.fu || LS.getItem("fuente"),
            br: this.b64final.br || this.client.br || clientInfo.br,
            os: this.b64final.os || this.client.os || clientInfo.os
          })
        };

        this.loading = true;

        await this.createAppointment({
          url: this.env.VUE_APPOINTMENTS_DEBMEDIA_SERVER,
          headers: {
            token_appointments: this.env.VUE_APPOINTMENTS_DEBMEDIA_API_KEY
          },
          queryData: "",
          data: data
        });
      }

      // Notification by sms
      this.sendNotification({
        code: this.responseAppointment.code,
        Celular: this.client.Celular,
        selectedDay: moment(
          this.selectedDay.zonedStartDate,
          "YYYY-MM-DD, HH:mm:ss"
        )
      });

      this.loading = false;
      this.modeScheduled = true;
    }
  }
};
</script>

<style scoped>
.botton-actions {
  position: absolute;
  top: 1.25rem;
  /* width: 100%; */
  left: -1rem;
}

.form-data {
  margin-top: 40px;
  background-color: #f9f9f9;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
  border-radius: 21.4881px;
}

.LinkColored {
  cursor: pointer;
  color: var(--color-secondary);
  line-height: 20px;
  margin-top: 28.44px;
}

.botton-actions-footer {
  position: fixed;
  bottom: 0;
  padding-bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 317.7px;
  width: 100%;
  z-index: 200;
}

.LinkUnderlined {
  color: var(--color-gray);
  text-decoration: underline;
}

.grayTextDesc {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;

  color: #66707e;
}

.blue {
  color: rgb(5, 169, 221);
}
.red {
  color: #ff0304;
}
.ProgramTitle {
  display: flex;
  overflow-wrap: normal;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.buttonOpacity {
  opacity: 1;
}

.text-error {
  color: #ff0304;
}

.link-btn-fake {
  width: fit-content;
  border: none;
  background: none;
  max-width: fit-content;
  margin: 0 auto;
  border-bottom: 3px solid #66707e;
  color: #66707e;
  font-size: 18px;
}

.blue {
  color: rgb(5, 169, 221);
}

.red {
  color: #ff0304;
}
</style>
