<template lang="pug">
  .Content
    Header
    .headerFixed(v-if="isntIntersection")
      button(@click="setExplanation(true)" v-show="!explanation" type="button").button-without-styles.py-3.px-4
        svg(width='20' height='19' viewbox='0 0 20 19' fill='none' xmlns='http://www.w3.org/2000/svg')
          path(d='M9.55301 16.8398L2.26586 9.55269L9.55301 2.26554M3.27796 9.55269L18.0547 9.55269' stroke='#ffff' stroke-width='3.12307' stroke-linecap='round' stroke-linejoin='round')
    //- div(v-show="explanation")
    //-   .w--100.text-center
    //-     .col-12.contain.color-colmedica-gray
    //-       h2.TitleSection Autorizaciones médicas Colmédicaa
    //-       p.TextSection.mt-3 Conoce el paso a paso de este canal:
    //-     video(
    //-       id="video"
    //-       src="https://bsix.assets.gobrilliant.com/colmedica/Colmedica_Promo_Video.mp4"
    //-       poster="https://bsix.assets.gobrilliant.com/colmedica/VideoColmedicaPoster.jpg"
    //-       playsinline
    //-       controls
    //-     ).VideoBordered.mt-3
    //-   .botton-actions-footer
    //-     .d-flex.justify-content-center
    //-       b-button(variant="bluecolmedica" class="text-center" size="sm" type="button" @click="pauseVideo").w-100.py-2.mx-1
    //-         span.font-md Continuar
    //-         //- Continue(size="24px")
    //-   // b-button(variant="bluecolmedica" class="my-2 btn-block align-self-center mx-auto" @click="explanation = !explanation") Next
    //- div(v-show="!explanation")
    .botton-actions.ml-4.pl-2
      .d-flex.justify-content-start
        //- b-button(variant="bluecolmedica" class="text-center" size="sm" type="button" @click="setExplanation(true)")
        //-   Back(size="24px")
        //-   span(class="text-sizing-12") Atrás
        //- | PASTE YOUR HTML CODE HERE
        button(@click="setExplanation(true)" type="button").button-without-styles
          svg(width='20' height='19' viewbox='0 0 20 19' fill='none' xmlns='http://www.w3.org/2000/svg')
            path(d='M9.55301 16.8398L2.26586 9.55269L9.55301 2.26554M3.27796 9.55269L18.0547 9.55269' stroke='#0058A2' stroke-width='3.12307' stroke-linecap='round' stroke-linejoin='round')
    .contain.color-colmedica-gray
      h2.TitleSection.text-center.m-0 Selecciona el
      h2.TitleSection.text-center.mb-4 servicio que requieres autorizar:
      div
        button(
          @click="setService(service)"
          v-for="service in services"
          :key="service.code_name"
        ).w-100.button-without-styles.my-2.mt-3
          stack-item.btn-block.align-self-center.mx-auto {{ service.label }}
</template>
<script>
import { mapActions, mapState } from "vuex";
import Header from "./components/Header";
import { getOneTimeToken } from "@/helpers/Meet";
import Back from "mdi-vue/ArrowLeftBold.vue";
import StackItem from "@/views/components/StackItem.vue";
import Continue from "mdi-vue/ArrowRightBold.vue";

export default {
  name: "Home",
  components: {
    Header,
    Back,
    Continue,
    StackItem
  },
  data: () => ({
    video: undefined,
    isntIntersection: false
  }),
  mounted() {
    this.startup();

    this.observer = new IntersectionObserver(this.handlerObserver, {
      threshold: 1.0
    });

    this.observer.observe(document.querySelector("#headerCalendarTitle"));
  },
  methods: {
    ...mapActions({
      goToView: "autoservice/goToView",
      setExplanation: "autoservice/setExplanation",
      selecteService: "autoservice/selecteService",
      updateB64Final: "autoservice/updateB64Final"
    }),
    async startup() {
      let to = await getOneTimeToken();
      let ma = 0;
      let fu = this.$route.path.substring(1);
      let su = Number(this.env.VUE_APP_DEBMEDIA_DEFAULT_BRANCH);
      let tr = Number(this.env.VUE_APP_DEBMEDIA_DEFAULT_TRAMITE);

      let jsonFinal = { to, tr, su, fu, ma };
      this.updateB64Final(jsonFinal);
      this.video = this.$el.querySelector("#video");
    },
    setService(service) {
      this.selecteService(service);
      this.goToView("Autenticate");
    },
    pauseVideo() {
      this.setExplanation(false);
      this.video.pause();
    },
    handlerObserver(entries) {
      entries.forEach(entry => {
        if (!entry.isIntersecting) {
          this.isntIntersection = true;
        } else {
          this.isntIntersection = false;
        }
      });
    }
  },
  computed: {
    ...mapState({
      services: state => state.autoservice.servicesAvailable,
      explanation: state => state.autoservice.explanation,
      env: state => state.env
    })
  }
};
</script>
<style scoped>
.contain {
  margin-top: 40px;
}

#video {
  width: 100%;
}

/* botom actions button - autoservice */
.botton-actions {
  position: absolute;
  top: 0;
  /* width: 100%; */
  margin-top: 24px;
  left: 0;
}

.botton-actions-footer {
  position: fixed;
  bottom: 0;
  padding-bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 317.7px;
  width: 100%;
  z-index: 200;
}

.home-service-button {
  font-size: 1.3rem;
}

.VideoBordered {
  border-radius: 15px;
  max-height: 194.68px;
  max-width: 349px;
}
</style>
